import { Controller } from "@hotwired/stimulus";
import cableStore from "../../utils/cable_subscriber";
import axios from "axios";

export default class extends Controller {
  static targets = ["invalidNumber"];

  connect() {
    cableStore.subscribe(this);
  }

  disconnect() {
    cableStore.unsubscribe(this);
  }

  cableChannel() {
    return {
      channel: "SmsThreadUpdatesChannel",
      id: Number(this.data.get("thread"))
    };
  }

  cableMessage({ msg, data }) {
    switch (msg) {
      case "Received":
        const response = JSON.parse(data.response);
        this.showInvalidNumber(Number(data.invalid_number));
        if (data.message) {
          this.renderMessage(response.sms_message_id, data.message);
          this.markRead(response);
        }
        return;
    }
  }

  renderMessage(messageId, html) {
    const message = this.element.querySelector(
      `[data-id="sms_message_${messageId}"]`
    );

    if (message) {
      message.outerHTML = html;
    } else {
      this.element.insertAdjacentHTML("beforeend", html);
    }
  }

  showInvalidNumber(invalid) {
    if (invalid != 1) return;

    this.invalidNumberTarget.classList.remove("d-none");
  }

  /**
   * If we are visible, then this new message is "read"
   */
  async markRead(response) {
    // If in a rendered drawer that is "hidden" offsetParent will not be null
    if (
      response.read_at !== null ||
      this.element.offsetParent === null ||
      window.getComputedStyle(this.element).visibility === "hidden"
    )
      return;

    await axios.patch(`/manage/sms_threads/${response.sms_thread_id}`);
    cableStore.sendMessage(this, {
      msg: "MarkRead",
      data: null
    });
  }

  retrySMS(ev) {
    let target = ev.target;
    if (!target.hasAttribute("data-message-id")) {
      target = ev.target.closest("[data-message-id]");
    }
    target.innerHTML = target.getAttribute("data-loading");
    const messageId = target.getAttribute("data-message-id");
    axios.patch(`${this.data.get("smsPath")}/${messageId}`);
  }
}
