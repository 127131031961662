import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (this.data.has("timeout")) {
      setTimeout(() => {
        const event = new CustomEvent("submit", {
          bubbles: true,
          cancelable: true
        });
        this.element.dispatchEvent(event);
      }, parseInt(this.data.get("timeout")));
    }
  }
}
